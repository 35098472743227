import Assistants from '@/components/Assistants/Assistants.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Chatbots',
  components: {
    Assistants,
    HeaderTopDashboard,
  },

  data () {
    return {
    }
  },
  methods: {

  },
  created () {
  },
  mounted () {
  },
}
